import { all } from 'redux-saga/effects';

import auth from '../reducers/auth/sagas';
import product from '../reducers/product/sagas';
import productSku from '../reducers/productSku/sagas';
import category from '../reducers/category/sagas';
import unit from '../reducers/unit/sagas';
import userMasterData from '../reducers/userMasterData/sagas';
import modul from '../reducers/module/sagas';
import subModule from '../reducers/subModule/sagas';
import gudang from '../reducers/gudang/sagas';
import moduleGroup from '../reducers/moduleGroup/sagas';
import rolePermission from '../reducers/rolePermission/sagas';
import penyewa from '../reducers/penyewa/sagas';
import stokIn from '../reducers/stokIn/sagas';
import stokOut from '../reducers/stokOut/sagas';
import customer from '../reducers/customer/sagas';
import transaksiPenjualan from '../reducers/transaksiPenjualan/sagas';
import stockInventory from '../reducers/stockInventory/sagas';
import networkType from '../reducers/networkType/sagas';
import komoditi from '../reducers/komoditi/sagas';
import perusahaan from '../reducers/perusahaan/sagas';
import transaksiPembeli from '../reducers/transaksiPembeli/sagas';
import user from '../reducers/userRole/sagas';
import jenisUsaha from '../reducers/jenisUsaha/sagas';
import network from '../reducers/network/sagas';
import reference from '../reducers/reference/sagas';
import supplier from '../reducers/supplier/sagas';
import packaging from '../reducers/packaging/sagas';
import tagihanPembelian from '../reducers/tagihanPembelian/sagas';
import returPembelian from '../reducers/returPembelian/sagas';
import tagihanPenjualan from '../reducers/tagihanPenjualan/sagas';
import returPenjualan from '../reducers/returPenjualan/sagas';
import approval from '../reducers/approval/sagas';
import log from '../reducers/Activity/sagas';
import profileUser from '../reducers/profileUser/sagas';
import stockBatch from '../reducers/stockBatch/sagas';
import inboundManagement from '../reducers/inboundManagement/sagas';
import inventoryType from '../reducers/inventoryType/sagas';
import dashboardMap from '../reducers/dashboardMap/sagas';
import outbound from '../reducers/outbound/sagas';
import deliveryMethod from '../reducers/deliveryMethod/sagas';
import stockMovement from '../reducers/stockMovement/sagas';
import transfer from '../reducers/transfer/sagas';
import delivery from '../reducers/delivery/sagas';
import warehouseType from '../reducers/warehouseType/sagas';
import inventoryChanges from '../reducers/inventoryChanges/sagas';
import stockAdjustment from '../reducers/stockAdjustment/sagas';
import fishfinChanges from '../reducers/fishfinChanges/sagas';
import voidTransaction from '../reducers/void/sagas';
import fishfinChangesReturn from '../reducers/fishfinChangesReturn/sagas';
import size from '../reducers/size/sagas';
import grade from '../reducers/grade/sagas';
import process from '../reducers/process/sagas';
import container from '../reducers/container/sagas';
import prospectWarehouse from '../reducers/prospectWarehouse/sagas';
import specificCondition from '../reducers/specificCondition/sagas';
import group from '../reducers/group/sagas';
import fishingArea from '../reducers/fishingArea/sagas';
import stockReservation from '../reducers/stockReservation/sagas';
import receiptInbound from '../reducers/receiptInbound/sagas';
import documentType from '../reducers/documentType/sagas';
import hsCode from '../reducers/hsCode/sagas';
import certification from '../reducers/certification/sagas';
import interestService from '../reducers/interest-service/sagas';
import tag from '../reducers/tag/sagas';
import StorageCategory from '../reducers/storageCategory/sagas';
import sales_order from '../reducers/sales_order/sagas';
import stockLocation from '../reducers/StockLocation/sagas';
import fishfinStock from '../reducers/fishfinStock/sagas';

export default function* rootSaga() {
  yield all([
    unit,
    userMasterData,
    modul,
    transaksiPembeli,
    tagihanPembelian,
    returPembelian,
    user,
    subModule,
    category,
    productSku,
    product,
    auth,
    reference,
    rolePermission,
    moduleGroup,
    gudang,
    penyewa,
    stokIn,
    stokOut,
    customer,
    transaksiPenjualan,
    stockInventory,
    networkType,
    komoditi,
    network,
    jenisUsaha,
    perusahaan,
    supplier,
    tagihanPenjualan,
    returPenjualan,
    approval,
    log,
    packaging,
    stockBatch,
    profileUser,
    inboundManagement,
    inventoryType,
    dashboardMap,
    transfer,
    stockMovement,
    deliveryMethod,
    outbound,
    delivery,
    inventoryChanges,
    warehouseType,
    stockAdjustment,
    fishfinChanges,
    voidTransaction,
    fishfinChangesReturn,
    size,
    grade,
    process,
    container,
    prospectWarehouse,
    specificCondition,
    group,
    fishingArea,
    stockReservation,
    receiptInbound,
    documentType,
    hsCode,
    certification,
    interestService,
    tag,
    StorageCategory,
    sales_order,
    stockLocation,
    fishfinStock
  ]);
}
