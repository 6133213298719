import service from './service';
import endpoint from './constantUrl';

export const getSalesOrder = (param) => {
  return service.get(`${endpoint.SALES_ORDER}/api/v1/sales_order`, param);
};

export const addSalesOrder = (data) => {
  return service.post(`${endpoint.SALES_ORDER}/api/v1/sales_order`, data);
};

export const getSalesOrderById = (id) => {
  return service.get(`${endpoint.SALES_ORDER}/api/v1/sales_order/${id}/`);
};

export const deleteSalesOrder = (id) => {
  return service.delete(`${endpoint.SALES_ORDER}/api/v1/sales_order/${id}/`);
};

export const updateSalesOrder = (data, id) => {
  return service.put(`${endpoint.SALES_ORDER}/api/v1/sales_order/${id}/`, data);
};

export const approvalSalesOrder = (id, data) => {
  return service.put(
    `${endpoint.SALES_ORDER}/api/v1/sales_order/${id}/approval/`,
    data
  );
};
