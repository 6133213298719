import React, { useEffect, useState } from 'react';

import { calculateDateDifference } from '../../utils';

const RealTimeText = ({ date, className }) => {
  const [dateTime, setDateTime] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(calculateDateDifference(date));
    }, 1000);

    return () => clearInterval(interval);
  }, [date]);

  return (
    <div>
      <p className={className}>{dateTime}</p>
    </div>
  );
};

export default RealTimeText;
