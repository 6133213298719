import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'stockLocation/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'stockLocation/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_STOCK_LOCATION = 'GET_DATA_STOCK_LOCATION';
export const getStockLocation = defaultActionCreator(
  GET_DATA_STOCK_LOCATION,
  'param'
);

export const GET_DATA_STOCK_STORAGE_LOCATION = 'GET_DATA_STOCK_LOCATION';
export const getDataStockStorageLocationById = defaultActionCreator(
  GET_DATA_STOCK_LOCATION,
  'param'
);

export const GET_DATA_STOCK_STORAGE_LOCATION_ALL =
  'GET_DATA_STOCK_STORAGE_LOCATION_ALL';
export const getDataStockStorageLocationsAll = defaultActionCreator(
  GET_DATA_STOCK_STORAGE_LOCATION_ALL,
  'param'
);

export const GET_DATA_STOCK_LOCATION_BY_ID = 'GET_DATA_STOCK_LOCATION_BY_ID';
export const getDataStockLocationById = defaultActionCreator(
  GET_DATA_STOCK_LOCATION_BY_ID,
  'id'
);

export const GET_DATA_STOCK_LOCATION_NEXT_OR_PREV =
  'GET_DATA_STOCK_LOCATION_NEXT_OR_PREV';
export const getDataStockLocationNextOrPrev = defaultActionCreator(
  GET_DATA_STOCK_LOCATION_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_STOCK_LOCATION = 'ADD_DATA_STOCK_LOCATION';
export const addDataStockLocation = defaultActionCreator(
  ADD_DATA_STOCK_LOCATION,
  'data',
  'log'
);

export const UPDATE_DATA_STOCK_LOCATION = 'UPDATE_DATA_STOCK_LOCATION';
export const updateDataStockLocation = defaultActionCreator(
  UPDATE_DATA_STOCK_LOCATION,
  'data',
  'id',
  'log'
);

export const DELETE_DATA_STOCK_LOCATION = 'DELETE_DATA_STOCK_LOCATION';
export const deleteDataStockLocation = defaultActionCreator(
  DELETE_DATA_STOCK_LOCATION,
  'id'
);

export const DOWNLOAD_DATA_STOCK_LOCATION = 'DOWNLOAD_DATA_STOCK_LOCATION';
export const downloadDataStockLocation = defaultActionCreator(
  DOWNLOAD_DATA_STOCK_LOCATION,
  'param'
);
