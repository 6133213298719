const endpoint = {
  ACCOUNT_URL: process.env.REACT_APP_API_ACCOUNT_URL,
  MASTERDATA_URL: process.env.REACT_APP_API_MASTER_DATA_URL,
  RENTAL_URL: process.env.REACT_APP_API_RENTAL_DATA_URL,
  POS_URL: process.env.REACT_APP_API_POS_URL,
  SALES_URL: process.env.REACT_APP_API_SALES_URL,
  NETWORK_URL: process.env.REACT_APP_API_NETWORK_URL,
  CASH_MANAGEMENT_WAREHOUSE:
    process.env.REACT_APP_API_CASH_MANAGEMENT_WAREHOUSE_URL,
  MARKETPLACE_URL: process.env.REACT_APP_API_MARKETPLACE_URL,
  FILE_MANAGEMENT_URL: process.env.REACT_APP_API_FILE_MANAGEMENT_URL,
  WAREHOUSE: process.env.REACT_APP_API_WAREHOUSE,
  DOWNLOAD: process.env.REACT_APP_API_DOWNLOAD,
  SALES_ORDER: process.env.REACT_APP_API_SALES
};

export default endpoint;
