import service from './service';
import endpoint from './constantUrl';

// Gudang
export const addGudang = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/warehouse/warehouse/`, data);
};
export const getGudang = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/warehouse/warehouse/`, param);
};
export const getGudangById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/warehouse/warehouse/${id}/`);
};

export const updateGudang = (data, id) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/${id}/`,
    data
  );
};

export const deleteGudang = (id) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/${id}/`
  );
};

export const downloadTemplateGudang = () => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/csv-template/`
  );
};

export const downloadDataGudang = (param) => {
  return service.getArrayBuffer(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/export-excel/`,
    param
  );
};

export const uploadGudang = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/import-csv/`,
    data
  );
};

export const activationGudang = (id62) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/${id62}/warehouse-activation/`
  );
};

export const tarifHistoriGudang = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/volatile-rate-history/`,
    data
  );
};

export const getPenyewaGudang = (id) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse/${id}/tenant/`
  );
};

// warehouse category

export const getWarehouseCategory = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/warehouse-category/`,
    param
  );
};

// warehouse location
export const getWarehouseLocationByWarehouseId = (param) => {
  return service.post(`${endpoint.MASTERDATA_URL}/warehouse/locations/`, param);
};

export const addWarehouseLocation = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/warehouse/location/`, data);
};

export const getWarehouseLocationById = (id62) => {
  return service.get(`${endpoint.MASTERDATA_URL}/warehouse/location/${id62}/`);
};

export const updateWarehouseLocationById = (data, id62) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/warehouse/location/${id62}/`,
    data
  );
};

export const deleteWarehouseLocationById = (id62) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/warehouse/location/${id62}/`
  );
};

// Storage Location
export const getStorageLocationByLocationId = (param) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-locations/`,
    param
  );
};

export const addStorageLocation = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-location/`,
    data
  );
};

export const getStorageLocationById = (id62) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-location/${id62}/`
  );
};

export const updateStorageLocationById = (data, id62) => {
  return service.put(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-location/${id62}/`,
    data
  );
};

export const deleteStorageLocationById = (id62) => {
  return service.delete(
    `${endpoint.MASTERDATA_URL}/warehouse/storage-location/${id62}/`
  );
};
