import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeSalesQuotation = [
  {
    path: '/sales-quotation',
    isParent: true,
    action: 'view',
    parent: '/sales-quotation',
    component: lazy(() =>
      retry(() =>
        import(
          /*·webpackChunkName:·"SalesQuotation"·*/ '../Pages/SalesQuotation'
        )
      )
    )
  },
  {
    path: '/sales-quotation/:id',
    action: 'view',
    parent: '/sales-quotation',
    component: lazy(() =>
      retry(() =>
        import(
          /*·webpackChunkName:·"SalesQuotation"·*/ '../Pages/SalesQuotation'
        )
      )
    )
  },
  {
    path: '/sales-quotation/add',
    parent: '/sales-quotation',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "SalesOrderAdd" */ '../Pages/SalesQuotation/salesQuotationAdd'
        )
      )
    )
  }
];
