import { SET_ERROR_FORM, SET_STATE } from './actions';

const initialState = {
  filter: {
    search: null,
    limit: 10,
    offset: 0,
    start_date_timestamp: '',
    end_date_timestamp: '',
    warehouse_id62: null,
    sort: null
  },
  currentPage: 0,
  loading: false,
  error: null,
  form: {},
  formValidation: {}
};

export default function assessmentReducer(state = initialState, action) {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.payload };
    case SET_ERROR_FORM:
      return { ...state, formValidation: { ...action.error } };
    default:
      return state;
  }
}
