import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function FlStatus({ children, variant, className, dot = true }) {
  return (
    <div
      className={
        (className || '') +
        `${' fl_status fl__' + variant + ' '}` +
        (dot ? ' use_dot' : '')
      }
    >
      <p>{children}</p>
    </div>
  );
}

FlStatus.propTypes = {
  dot: PropTypes.bool,
  children: PropTypes.any,
  variant: PropTypes.oneOf([
    'red-0',
    'red-1',
    'red-2',
    'red-3',
    'red-4',
    'red-5',
    'red-6',
    'red-7',
    'red-8',
    'red-9',
    'red-10',
    'blue-0',
    'blue-1',
    'blue-2',
    'blue-3',
    'blue-4',
    'blue-5',
    'blue-6',
    'blue-7',
    'blue-8',
    'blue-9',
    'green-0',
    'green-1',
    'green-2',
    'green-3',
    'green-4',
    'green-5',
    'green-6',
    'green-7',
    'green-8',
    'green-9',
    'purple-0',
    'purple-1',
    'purple-2',
    'purple-3',
    'yellow-0',
    'yellow-1',
    'yellow-2',
    'yellow-3',
    'yellow-4',
    'yellow-5',
    'grey-1',
    'light-green'
  ])
};

export default FlStatus;
