import service from './service';
import endpoint from './constantUrl';

// Size
export const addSalesOrder = (data) => {
  return service.post(`${endpoint.MASTERDATA_URL}/product/size/`, data);
};

export const getSalesOrder = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/size/`, param);
};

export const getSalesOrderById = (id) => {
  return service.get(`${endpoint.MASTERDATA_URL}/product/size/${id}/`);
};

export const updateSalesOrder = (data, id) => {
  return service.put(`${endpoint.MASTERDATA_URL}/product/size/${id}/`, data);
};

export const deleteSalesOrder = (id) => {
  return service.delete(`${endpoint.MASTERDATA_URL}/product/size/${id}/`);
};

export const getCustomerList = (param) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/dashboard/customer-list/`,
    param
  );
};
