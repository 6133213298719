import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeSalesInvoice = [
  {
    path: '/sales-invoice',
    isParent: true,
    action: 'view',
    parent: '/sales-invoice',
    component: lazy(() =>
      retry(() =>
        import(/*·webpackChunkName:·"SalesInvoice"·*/ '../Pages/SalesInvoice')
      )
    )
  },
  {
    path: '/sales-invoice/:id',
    action: 'view',
    parent: '/sales-invoice',
    component: lazy(() =>
      retry(() =>
        import(/*·webpackChunkName:·"SalesInvoice"·*/ '../Pages/SalesInvoice')
      )
    )
  },
  {
    path: '/sales-invoice/add',
    parent: '/sales-invoice',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "SalesInvoiceAdd" */ '../Pages/SalesInvoice/salesInvoiceAdd'
        )
      )
    )
  }
];
