import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  getFishfinStock,
  getFishfinStockBatches,
  getFishfinStockDetailBatch
} from '../../../services/fishfin';
import {
  SET_STATE,
  GET_DATA_FISHFIN_STOCK,
  GET_DATA_FISHFIN_STOCK_LIST_BATCH,
  GET_DATA_FISHFIN_STOCK_DETAIL_BATCH
} from './actions';

export function* GET_FISHFIN_STOCK(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getFishfinStock, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_FISHFIN_STOCK_LIST_BATCH(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingListBatch: true, dataListBatch: null }
  });
  try {
    const { data } = yield call(
      getFishfinStockBatches,
      action.id,
      action.param
    );
    yield put({ type: SET_STATE, payload: { dataListBatch: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingListBatch: false } });
  }
}

export function* GET_FISHFIN_STOCK_DETAIL_BATCH(action) {
  yield put({
    type: SET_STATE,
    payload: { loading: true, dataDetailBatch: null }
  });
  try {
    const { data } = yield call(getFishfinStockDetailBatch, action.param);
    yield put({ type: SET_STATE, payload: { dataDetailBatch: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export default all([
  takeLatest(GET_DATA_FISHFIN_STOCK, GET_FISHFIN_STOCK),
  takeLatest(GET_DATA_FISHFIN_STOCK_LIST_BATCH, GET_FISHFIN_STOCK_LIST_BATCH),
  takeLatest(
    GET_DATA_FISHFIN_STOCK_DETAIL_BATCH,
    GET_FISHFIN_STOCK_DETAIL_BATCH
  )
]);
