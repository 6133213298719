import service from './service';
import endpoint from './constantUrl';

export const getStockLocations = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-location/filter/`,
    param
  );
};

export const getStockStorageLocationsAll = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-storage-location/all/`,
    param
  );
};

export const getStockStorageLocations = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-storage-location/filter/`,
    param
  );
};

export const addStockLocation = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-storage-location/`,
    data
  );
};

export const getStockLocationById = (id) => {
  return service.get(`${endpoint.POS_URL}/inventory/stock-location/${id}/`);
};

export const deleteStockLocation = (id) => {
  return service.delete(
    `${endpoint.POS_URL}/inventory/stock-storage-location/${id}/`
  );
};

export const updateStockLocation = (data, id) => {
  return service.put(
    `${endpoint.POS_URL}/inventory/stock-storage-location/${id}/`,
    data
  );
};

export const downloadExcelStockLocation = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/stock-location/export-excel/`,
    data
  );
};

export const exportStockLocationExcel = (param) => {
  return service.postDownloadService(
    `${endpoint.DOWNLOAD}/inventory/stock-location/excel`,
    param
  );
};
