import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'fishfinStock/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'fishfinStock/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_FISHFIN_STOCK = 'GET_DATA_FISHFIN_STOCK';
export const getDataFishfinStock = defaultActionCreator(
  GET_DATA_FISHFIN_STOCK,
  'param'
);

export const GET_DATA_FISHFIN_STOCK_LIST_BATCH =
  'GET_DATA_FISHFIN_STOCK_LIST_BATCH';
export const getDataFishfinStockListBatch = defaultActionCreator(
  GET_DATA_FISHFIN_STOCK_LIST_BATCH,
  'id',
  'param'
);

export const GET_DATA_FISHFIN_STOCK_DETAIL_BATCH =
  'GET_DATA_FISHFIN_STOCK_DETAIL_BATCH';
export const getDataFishfinStockDetailBatch = defaultActionCreator(
  GET_DATA_FISHFIN_STOCK_DETAIL_BATCH,
  'param'
);
