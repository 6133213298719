import React from 'react';
import { Navbar, Button, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FlStatus from '../Status';
import FishLogLogo from '../../assets/images/fishlog.svg';
import RealTimeText from '../RealTimeText';

import { capitalizeFirstLetter, removeLocalstorage } from '../../utils';
import trans from '../../utils/useTranslate';
import constants from '../../utils/constants';
import './styles.scss';

function BaseNavbar({ isHideMenu, setIsHideMenu, name, handleOpenNotif }) {
  const general = useSelector((state) => state.general);
  const dataNotif = general?.listNotif;
  const countNotif = general?.countUnread;

  // eslint-disable-next-line react/display-name
  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <a
      style={{
        textDecoration: 'none'
      }}
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <FlStatus className="btn-auth" variant="light-green" size="big-bold">
        <span
          style={{
            cursor: 'pointer',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '19px'
          }}
        >
          {capitalizeFirstLetter(name)}
        </span>
        <i className="bx bx-caret-down ms-1" />
      </FlStatus>
    </a>
  ));

  // eslint-disable-next-line react/display-name
  const notificationToggle = React.forwardRef(({ onClick }, ref) => (
    <div
      className="btn-notification me-4"
      href="#"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        handleReadNotif();
      }}
    >
      <i className="bx bx-bell"></i>

      {countNotif > 0 ? <span className="badge">{countNotif}</span> : ''}
    </div>
  ));

  const logout = () => {
    removeLocalstorage(true);
  };

  const linkToWhatsapp = () => {
    window.open('https://wa.me/' + constants.whatsApp, '_blank');
  };

  const hideSideBar = () => {
    if (isHideMenu) localStorage.setItem('expand', false);
    else localStorage.setItem('expand', true);

    setIsHideMenu(!isHideMenu);
  };

  const handleReadNotif = () => {
    if (countNotif > 0) handleOpenNotif();
  };

  return (
    <Navbar className="px-4 py-1 border border-1 fl_navbar fixed-top">
      <button onClick={hideSideBar} className="text-dark ic-menu">
        <i className="bx bx-menu bx-big" />
      </button>

      <Navbar.Brand href="/" className="ms-3">
        <img src={FishLogLogo} alt="fishlog" height="30px" />
      </Navbar.Brand>

      <Navbar.Collapse className="justify-content-end">
        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-custom-components"
            as={notificationToggle}
          ></Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              left: 'unset',
              right: 0,
              marginTop: '0.7rem',
              width: '200px'
            }}
          >
            <p className="notification-title">Notifications</p>

            <hr
              style={{
                color: '#EEEEEE',
                opacity: 'unset',
                margin: '0.5rem'
              }}
            />

            <div style={{ maxHeight: '360px', overflow: 'auto' }}>
              {/* <Dropdown.Divider /> */}
              {dataNotif?.results?.map((item, index) => (
                <Dropdown.ItemText
                  key={index}
                  // eventKey="1"
                  className="notification-container"
                >
                  <p className="text-content">{item?.placeholders[0]}</p>
                  <RealTimeText
                    className={'text-date'}
                    date={item?.timestamp}
                  />
                </Dropdown.ItemText>
              ))}
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Button
          variant="outline-primary btn-help"
          className="me-4"
          onClick={linkToWhatsapp}
        >
          {trans(['Help'])}
        </Button>

        <Dropdown>
          <Dropdown.Toggle
            id="dropdown-custom-components"
            as={CustomToggle}
          ></Dropdown.Toggle>
          <Dropdown.Menu style={{ left: 'unset', right: 0, marginTop: '1rem' }}>
            <Dropdown.Item eventKey="1" className="d-flex align-items-center">
              <Button
                variant="outline-primary btn-help-collapse"
                className="w-100"
                onClick={linkToWhatsapp}
              >
                {trans(['Help'])}
              </Button>
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              href="/profile-user"
            >
              <i className="bx bxs-user-circle me-2"></i>{' '}
              {trans(['Profile User'])}
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="1"
              className="d-flex align-items-center"
              onClick={logout}
            >
              <i className="bx bx-log-in-circle me-2"></i> {trans(['Logout'])}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Collapse>
    </Navbar>
  );
}

BaseNavbar.propTypes = {
  isHideMenu: PropTypes.bool.isRequired,
  setIsHideMenu: PropTypes.func.isRequired
};

export default BaseNavbar;
