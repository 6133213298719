import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'sales_order/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'sales_order/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_SALES_ORDER = 'GET_DATA_SALES_ORDER';
export const getDataSalesOrder = defaultActionCreator(
  GET_DATA_SALES_ORDER,
  'param'
);

export const GET_DATA_SALES_ORDER_BY_ID = 'GET_DATA_SALES_ORDER_BY_ID';
export const getDataSalesOrderById = defaultActionCreator(
  GET_DATA_SALES_ORDER_BY_ID,
  'id'
);

export const GET_DATA_SALES_ORDER_NEXT_OR_PREV =
  'GET_DATA_SALES_ORDER_NEXT_OR_PREV';
export const getDataSalesOrderNextOrPrev = defaultActionCreator(
  GET_DATA_SALES_ORDER_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_SALES_ORDER = 'ADD_DATA_SALES_ORDER';
export const addDataSalesOrder = defaultActionCreator(
  ADD_DATA_SALES_ORDER,
  'data',
  'log'
);

export const UPDATE_DATA_SALES_ORDER = 'UPDATE_DATA_SALES_ORDER';
export const updateDataSalesOrder = defaultActionCreator(
  UPDATE_DATA_SALES_ORDER,
  'data',
  'id',
  'log'
);

export const DELETE_DATA_SALES_ORDER = 'DELETE_DATA_SALES_ORDER';
export const deleteDataSalesOrder = defaultActionCreator(
  DELETE_DATA_SALES_ORDER,
  'id'
);

export const APPROVAL_DATA_SALES_ORDER = 'APPROVAL_DATA_SALES_ORDER';
export const approvalDataSalesOrder = defaultActionCreator(
  APPROVAL_DATA_SALES_ORDER,
  'id',
  'data'
);
