import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'outbound/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'outbound/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_OUTBOUND = 'GET_DATA_OUTBOUND';
export const getDataOutbound = defaultActionCreator(GET_DATA_OUTBOUND, 'param');

export const GET_DATA_INVENTORY_LOCATIONS = 'GET_DATA_INVENTORY_LOCATIONS';
export const getDataInventoryLocation = defaultActionCreator(
  GET_DATA_INVENTORY_LOCATIONS,
  'param'
);

export const GET_DATA_INVENTORY_STORAGE_LOCATIONS =
  'GET_DATA_INVENTORY_STORAGE_LOCATIONS';
export const getDataInventoryStorageLocation = defaultActionCreator(
  GET_DATA_INVENTORY_STORAGE_LOCATIONS,
  'param'
);

export const GET_DATA_OUTBOUND_BY_ID = 'GET_DATA_OUTBOUND_BY_ID';
export const getDataOutboundById = defaultActionCreator(
  GET_DATA_OUTBOUND_BY_ID,
  'id'
);

export const GET_DATA_OUTBOUND_NEXT_OR_PREV = 'GET_DATA_OUTBOUND_NEXT_OR_PREV';
export const getDataOutboundNextOrPrev = defaultActionCreator(
  GET_DATA_OUTBOUND_NEXT_OR_PREV,
  'link'
);

export const ADD_DATA_OUTBOUND = 'ADD_DATA_OUTBOUND';
export const addDataOutbound = defaultActionCreator(
  ADD_DATA_OUTBOUND,
  'data',
  'log'
);

export const UPDATE_DATA_OUTBOUND = 'UPDATE_DATA_OUTBOUND';
export const updateDataOutbound = defaultActionCreator(
  UPDATE_DATA_OUTBOUND,
  'data',
  'id',
  'log'
);

export const DELETE_DATA_OUTBOUND = 'DELETE_DATA_OUTBOUND';
export const deleteDataOutbound = defaultActionCreator(
  DELETE_DATA_OUTBOUND,
  'id'
);

export const APPROVAL_DATA_OUTBOUND = 'APPROVAL_DATA_OUTBOUND';
export const approvalDataOutbound = defaultActionCreator(
  APPROVAL_DATA_OUTBOUND,
  'id',
  'data'
);
export const DELIVERY_DATA_OUTBOUND = 'DELIVERY_DATA_OUTBOUND';
export const deliveryDataOutbound = defaultActionCreator(
  DELIVERY_DATA_OUTBOUND,
  'id',
  'data'
);

export const DOWNLOAD_DATA_OUTBOUND = 'DOWNLOAD_DATA_OUTBOUND';
export const downloadDataOutbound = defaultActionCreator(
  DOWNLOAD_DATA_OUTBOUND,
  'param'
);
