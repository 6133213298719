import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeStockLocation = [
  {
    path: '/stock-location',
    parent: '/stock-location',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(/* webpackChunkName: "StockLocation" */ '../Pages/StockLocation')
      )
    )
  },
  {
    path: '/stock-location/:id',
    parent: '/stock-location',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "StockLocationDetail" */ '../Pages/StockLocation/detail'
        )
      )
    )
  }
];
