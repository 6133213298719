import { lazy } from 'react';
import { retry } from '../utils/index';

export const routePos = [
  {
    path: '/pos/warehouse',
    parent: '/pos',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContact" */ '../Pages/Pos/Warehouse/Warehouse'
        )
      )
    )
  },
  {
    path: '/pos/warehouse/:id',
    parent: '/pos',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactAdd" */ '../Pages/Pos/WarehouseDetail/WarehouseDetail'
        )
      )
    )
  },
  {
    path: '/pos/warehouse/account-balance/:id',
    parent: '/pos',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactAdd" */ '../Pages/Pos/AccountBalance/AccountBalance'
        )
      )
    )
  },
  {
    path: '/pos/warehouse/start-session/:id',
    parent: '/pos',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactAdd" */ '../Pages/Pos/SessionDetail/SessionDetail'
        )
      )
    )
  },
  {
    path: '/pos/warehouse/customer-information/:id',
    parent: '/pos',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactAdd" */ '../Pages/Pos/CustomerInformation/CustomerInformation'
        )
      )
    )
  },
  {
    path: '/pos/warehouse/product-detail/:id',
    parent: '/pos',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactAdd" */ '../Pages/Pos/ProductDetail/ProductDetail'
        )
      )
    )
  },
  {
    path: '/pos/warehouse/opened-session/invoice/:id',
    parent: '/pos',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "ManageContactAdd" */ '../Pages/Pos/Invoice/Invoice'
        )
      )
    )
  }
];
