import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getOutbound,
  getOutboundById,
  addOutbound,
  updateOutbound,
  deleteOutbound,
  approvalOutbound,
  deliveryOutbound,
  exportOutboundExcel,
  getInventoryLocation,
  getInventoryStorageLocation
} from '../../../services/outbound';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_OUTBOUND,
  GET_DATA_OUTBOUND_BY_ID,
  GET_DATA_INVENTORY_LOCATIONS,
  GET_DATA_INVENTORY_STORAGE_LOCATIONS,
  GET_DATA_OUTBOUND_NEXT_OR_PREV,
  ADD_DATA_OUTBOUND,
  UPDATE_DATA_OUTBOUND,
  DELETE_DATA_OUTBOUND,
  APPROVAL_DATA_OUTBOUND,
  DOWNLOAD_DATA_OUTBOUND,
  DELIVERY_DATA_OUTBOUND
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';

export function* GET_OUTBOUND(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getOutbound, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_OUTBOUND_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getOutboundById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_INVENTORY_LOCATIONS(action) {
  yield put({
    type: SET_STATE,
    payload: { dataLocations: null, loading: true }
  });
  try {
    const { data } = yield call(getInventoryLocation, action.param);
    yield put({ type: SET_STATE, payload: { dataLocations: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_INVENTORY_STORAGE_LOCATIONS(action) {
  yield put({
    type: SET_STATE,
    payload: { dataStorages: null, loading: true }
  });
  try {
    const { data } = yield call(getInventoryStorageLocation, action.param);
    yield put({ type: SET_STATE, payload: { dataStorages: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_OUTBOUND_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_OUTBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(addOutbound, action.data);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Outbound', action.log.desc);
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* UPDATE_OUTBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(updateOutbound, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Outbound', action.log.desc);
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DELETE_OUTBOUND(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteOutbound, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Outbound', 'Delete Outbound');
    let message = 'Successfully deleting the Draft';
    let model = 'success';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message, model } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* APPROVAL_OUTBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingApproval: true } });
  try {
    yield call(approvalOutbound, action.id, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Outbound', 'Approval Outbound');
    let message = 'Transaction Has Been Approved';
    let model = 'success';
    if (!action?.data?.is_approved) {
      message = 'Transaction Has Been Rejected';
      model = 'fail';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message, model } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingApproval: false }
    });
  }
}
export function* DELIVERY_OUTBOUND(action) {
  yield put({ type: SET_STATE, payload: { loadingDelivery: true } });
  try {
    yield call(deliveryOutbound, action.id, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Outbound', 'Delivery Outbound');
    let message = 'Transactions On The Go';
    let model = 'success';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message, model } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingDelivery: false }
    });
  }
}

export function* DOWNLOAD_OUTBOUND(action) {
  try {
    const response = yield call(exportOutboundExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_OUTBOUND, GET_OUTBOUND),
  takeLatest(GET_DATA_OUTBOUND_BY_ID, GET_OUTBOUND_BY_ID),
  takeLatest(GET_DATA_OUTBOUND_NEXT_OR_PREV, GET_DATA_OUTBOUND_NEXT_OR_PREV),
  takeLatest(GET_DATA_INVENTORY_LOCATIONS, GET_INVENTORY_LOCATIONS),
  takeLatest(
    GET_DATA_INVENTORY_STORAGE_LOCATIONS,
    GET_INVENTORY_STORAGE_LOCATIONS
  ),
  takeLatest(ADD_DATA_OUTBOUND, ADD_OUTBOUND),
  takeLatest(DOWNLOAD_DATA_OUTBOUND, DOWNLOAD_OUTBOUND),
  takeLatest(UPDATE_DATA_OUTBOUND, UPDATE_OUTBOUND),
  takeLatest(DELETE_DATA_OUTBOUND, DELETE_OUTBOUND),
  takeLatest(APPROVAL_DATA_OUTBOUND, APPROVAL_OUTBOUND),
  takeLatest(DELIVERY_DATA_OUTBOUND, DELIVERY_OUTBOUND)
]);
