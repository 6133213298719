import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_STOCK_LOCATION,
  GET_DATA_STOCK_LOCATION_BY_ID,
  GET_DATA_STOCK_LOCATION_NEXT_OR_PREV,
  ADD_DATA_STOCK_LOCATION,
  DOWNLOAD_DATA_STOCK_LOCATION,
  UPDATE_DATA_STOCK_LOCATION,
  DELETE_DATA_STOCK_LOCATION,
  GET_DATA_STOCK_STORAGE_LOCATION,
  GET_DATA_STOCK_STORAGE_LOCATION_ALL
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog, handleDownload, logMatches } from '../../../utils';
import {
  addStockLocation,
  deleteStockLocation,
  exportStockLocationExcel,
  getStockLocationById,
  getStockLocations,
  getStockStorageLocations,
  getStockStorageLocationsAll,
  updateStockLocation
} from '../../../services/StockLocation';

export function* GET_STOCK_LOCATION(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getStockLocations, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_STORAGE_LOCATION(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getStockStorageLocations, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_STORAGE_LOCATION_ALL(action) {
  yield put({
    type: SET_STATE,
    payload: { dataStorage: null, loadingStorage: true }
  });
  try {
    const { data } = yield call(getStockStorageLocationsAll, action.param);
    yield put({ type: SET_STATE, payload: { dataStorage: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingStorage: false } });
  }
}

export function* GET_STOCK_LOCATION_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getStockLocationById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_STOCK_LOCATION_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_STOCK_LOCATION(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(addStockLocation, action.data);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Inventory', action.log.desc);

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* UPDATE_STOCK_LOCATION(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    // console.log(action.data, action.id, 'SAGAS UPDATE INBOUND');
    yield call(updateStockLocation, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'stockLocation', action.log.desc);

    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DELETE_STOCK_LOCATION(action) {
  yield put({ type: SET_STATE, payload: { loadingDelete: true } });
  try {
    yield call(deleteStockLocation, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'stockLocation', 'Delete Stock Location');
    yield call(createLog, body);
    let message = 'Successfully deleting the Draft';

    yield put({ type: SET_STATE, payload: { isDelete: true, message } });
  } catch ({ message: error }) {
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingDelete: false }
    });
  }
}

export function* DOWNLOAD_STOCK_LOCATION(action) {
  try {
    const response = yield call(exportStockLocationExcel, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    console.log(error);
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export default all([
  takeLatest(GET_DATA_STOCK_LOCATION, GET_STOCK_LOCATION),
  takeLatest(GET_DATA_STOCK_STORAGE_LOCATION, GET_STOCK_STORAGE_LOCATION),
  takeLatest(
    GET_DATA_STOCK_STORAGE_LOCATION_ALL,
    GET_STOCK_STORAGE_LOCATION_ALL
  ),
  takeLatest(GET_DATA_STOCK_LOCATION_BY_ID, GET_STOCK_LOCATION_BY_ID),
  takeLatest(
    GET_DATA_STOCK_LOCATION_NEXT_OR_PREV,
    GET_STOCK_LOCATION_NEXT_OR_PREV
  ),
  takeLatest(ADD_DATA_STOCK_LOCATION, ADD_STOCK_LOCATION),
  takeLatest(DOWNLOAD_DATA_STOCK_LOCATION, DOWNLOAD_STOCK_LOCATION),
  takeLatest(UPDATE_DATA_STOCK_LOCATION, UPDATE_STOCK_LOCATION),
  takeLatest(DELETE_DATA_STOCK_LOCATION, DELETE_STOCK_LOCATION)
]);
