import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  SET_STATE,
  SET_ERROR_FORM,
  GET_DATA_SALES_ORDER,
  GET_DATA_SALES_ORDER_BY_ID,
  GET_DATA_SALES_ORDER_NEXT_OR_PREV,
  ADD_DATA_SALES_ORDER,
  UPDATE_DATA_SALES_ORDER,
  DELETE_DATA_SALES_ORDER,
  APPROVAL_DATA_SALES_ORDER
} from './actions';
import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';
import {
  addSalesOrder,
  approvalSalesOrder,
  deleteSalesOrder,
  getSalesOrder,
  getSalesOrderById,
  updateSalesOrder
} from '../../../services/sales_order';

export function* GET_SALES_ORDER(action) {
  yield put({ type: SET_STATE, payload: { data: null, loading: true } });
  try {
    const { data } = yield call(getSalesOrder, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_SALES_ORDER_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { detailData: null, loading: true } });
  try {
    const { data } = yield call(getSalesOrderById, action.id);
    yield put({ type: SET_STATE, payload: { detailData: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_SALES_ORDER_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* ADD_SALES_ORDER(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(addSalesOrder, action.data);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'Sales', action.log.desc);
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield put({ type: SET_STATE, payload: { isAdd: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* UPDATE_SALES_ORDER(action) {
  yield put({ type: SET_STATE, payload: { loadingDraft: true } });
  try {
    yield call(updateSalesOrder, action.data, action.id);
    yield setIpUser();
    const body = bodyLog(action.log.log_type, 'sales_order', action.log.desc);
    yield call(createLog, body);
    let message = 'Your Data Has Been Submitted';
    if (action?.data?.is_draft) {
      message = 'Successfully Save as Draft';
    }
    yield put({ type: SET_STATE, payload: { isUpdate: true, message } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingDraft: false } });
  }
}

export function* DELETE_SALES_ORDER(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteSalesOrder, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'sales_order', 'Delete Outbound');
    let message = 'Successfully deleting the Draft';
    let model = 'success';
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isDelete: true, message, model } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* APPROVAL_SALES_ORDER(action) {
  yield put({ type: SET_STATE, payload: { loadingApproval: true } });
  try {
    yield call(approvalSalesOrder, action.id, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'sales_order', 'Approval Outbound');
    let message = 'Transaction Has Been Approved';
    let model = 'success';
    if (!action?.data?.is_approved) {
      message = 'Transaction Has Been Rejected';
      model = 'fail';
    }
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true, message, model } });
  } catch ({ response }) {
    let error = response?.data;
    if (response?.status === 500) {
      error = { error_code: 500, message: { error: response?.statusText } };
    }
    yield put({ type: SET_ERROR_FORM, error: error });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingApproval: false }
    });
  }
}

export default all([
  takeLatest(GET_DATA_SALES_ORDER, GET_SALES_ORDER),
  takeLatest(GET_DATA_SALES_ORDER_BY_ID, GET_SALES_ORDER_BY_ID),
  takeLatest(GET_DATA_SALES_ORDER_NEXT_OR_PREV, GET_SALES_ORDER_NEXT_OR_PREV),
  takeLatest(ADD_DATA_SALES_ORDER, ADD_SALES_ORDER),
  takeLatest(UPDATE_DATA_SALES_ORDER, UPDATE_SALES_ORDER),
  takeLatest(DELETE_DATA_SALES_ORDER, DELETE_SALES_ORDER),
  takeLatest(APPROVAL_DATA_SALES_ORDER, APPROVAL_SALES_ORDER)
]);
