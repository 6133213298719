import service from './service';
import endpoint from './constantUrl';

export const getCountry = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/reference/country/`, param);
};

export const getProvince = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/reference/province/`, param);
};

export const getRegency = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/reference/regency/`, param);
};

export const getDistrict = (param) => {
  return service.get(`${endpoint.MASTERDATA_URL}/reference/district/`, param);
};

export const masterDataBase64Upload = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/reference/base64-upload/`,
    data
  );
};

export const accountBase64Upload = (data) => {
  return service.post(`${endpoint.ACCOUNT_URL}/reference/base64-upload/`, data);
};

export const posBase64Upload = (data) => {
  return service.post(`${endpoint.POS_URL}/reference/base64-upload/`, data);
};

export const rentalBase64Upload = (data) => {
  return service.post(`${endpoint.POS_URL}/reference/base64-upload/`, data);
};

export const CashBase64Upload = (data) => {
  return service.post(
    `${endpoint.CASH_MANAGEMENT_WAREHOUSE}/reference/base64-upload/`,
    data
  );
};

//file management attachment

export const uploadFile = (data, flow) => {
  return service.postMultipart(
    `${endpoint.FILE_MANAGEMENT_URL}/attachment/${flow}`,
    data
  );
};

export const getGeolocation = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/reference/geocode/location/`,
    data
  );
};

export const getGeolocationV2 = (data) => {
  return service.post(
    `${endpoint.MASTERDATA_URL}/reference/geocode/location/v2/`,
    data
  );
};

export const getArea = (data) => {
  return service.get(`${endpoint.MASTERDATA_URL}/reference/area/`, data);
};

export const getSubarea = (data) => {
  return service.get(`${endpoint.MASTERDATA_URL}/reference/subarea/`, data);
};

export const getFishingMethod = (data) => {
  return service.get(
    `${endpoint.MASTERDATA_URL}/reference/fishing-method/`,
    data
  );
};

export const uploadFileWatermark = (data) => {
  return service.postB2BPublic(
    `https://adminapi.b2b.fishlog-staging.net/api/wms/v1/upload`,
    data
  );
};
