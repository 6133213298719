import { all, put, takeLatest, call } from 'redux-saga/effects';
import { getDataNextOrPrev } from '../../../services';
import {
  getGudang,
  getGudangById,
  addGudang,
  updateGudang,
  deleteGudang,
  downloadTemplateGudang,
  downloadDataGudang,
  uploadGudang,
  activationGudang,
  tarifHistoriGudang,
  getWarehouseCategory,
  getWarehouseLocationByWarehouseId,
  addWarehouseLocation,
  updateWarehouseLocationById,
  getWarehouseLocationById,
  deleteWarehouseLocationById,
  getStorageLocationByLocationId,
  getStorageLocationById,
  addStorageLocation,
  updateStorageLocationById,
  deleteStorageLocationById
} from '../../../services/gudang';
import { handleDownload, logMatches } from '../../../utils';
import {
  SET_STATE,
  GET_DATA_GUDANG,
  GET_DATA_GUDANGS,
  GET_DATA_GUDANG_BY_ID,
  ADD_DATA_GUDANG,
  UPDATE_DATA_GUDANG,
  DELETE_DATA_GUDANG,
  GET_DATA_GUDANG_NEXT_OR_PREV,
  SET_ERROR_FORM,
  DOWNLOAD_DATA_GUDANG,
  DOWNLOAD_TEMPLATE_GUDANG,
  UPLOAD_DATA_GUDANG,
  ACTIVATION_DATA_GUDANG,
  TARIF_HISTORI_DATA_GUDANG,
  GET_DATA_GUDANG_2,
  GET_DATA_WAREHOUSE_CATEGORY,
  GET_DATA_WAREHOUSE_LOCATION_BY_WH_ID,
  ADD_DATA_WAREHOUSE_LOCATION,
  UPDATE_DATA_WAREHOUSE_LOCATION,
  GET_DATA_WAREHOUSE_LOCATION_BY_ID,
  DELETE_DATA_WAREHOUSE_LOCATION_BY_ID,
  GET_DATA_STORAGE_LOCATION_BY_LOCATION_ID,
  GET_DATA_STORAGE_LOCATION_BY_ID,
  ADD_DATA_STORAGE_LOCATION,
  UPDATE_DATA_STORAGE_LOCATION,
  DELETE_DATA_STORAGE_LOCATION_BY_ID
} from './actions';

import { createLog } from '../../../services/Activity';
import { setIpUser, bodyLog } from '../../../utils';

export function* GET_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGudang, action.param);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_GUDANG_2(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data2: null } });
  try {
    const { data } = yield call(getGudang, action.param);
    yield put({ type: SET_STATE, payload: { data2: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* GET_GUDANGS(action) {
  yield put({
    type: SET_STATE,
    payload: { loading: true, [action.key]: null }
  });
  try {
    const { data } = yield call(getGudang, action.param);
    yield put({ type: SET_STATE, payload: { [action.key]: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GUDANG_BY_ID(action) {
  yield put({ type: SET_STATE, payload: { loading: true, data: null } });
  try {
    const { data } = yield call(getGudangById, action.id);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}

export function* GET_GUDANG_NEXT_OR_PREV(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(getDataNextOrPrev, action.link);
    yield put({ type: SET_STATE, payload: { data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* ADD_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(addGudang, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Warehouse', 'Create Warehouse');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isAdd: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* UPDATE_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(updateGudang, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Warehouse', 'Edit Warehouse');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* DELETE_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(deleteGudang, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Warehouse', 'Delete Warehouse');
    yield call(createLog, body);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loading: false }
    });
  }
}

export function* DOWNLOAD_TEMPLATE_GUDANG_F() {
  // yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    const { data } = yield call(downloadTemplateGudang);
    yield handleDownload(data, 'gudang_template.csv');
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* DOWNLOAD_GUDANG(action) {
  try {
    const response = yield call(downloadDataGudang, action.param);
    const filename = yield logMatches(response.headers['content-disposition']);
    yield handleDownload(response.data, filename[0]);
  } catch ({ message: error }) {
    // yield put({ type: SET_STATE, payload: { error } });
  }
}

export function* UPLOAD_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loadingUpload: true } });
  try {
    yield call(uploadGudang, action.data);
    yield put({
      type: SET_STATE,
      payload: { isUpload: true, isUpdate: true }
    });
  } catch ({ response }) {
    yield put({
      type: SET_STATE,
      payload: { errorUpload: response?.data, isUpload: false }
    });
  } finally {
    yield put({
      type: SET_STATE,
      payload: { loadingUpload: false }
    });
  }
}

export function* ACTIVATION_GUDANG(action) {
  yield put({ type: SET_STATE, payload: { loading: true } });
  try {
    yield call(activationGudang, action.id62);
    yield put({ type: SET_STATE, payload: { isUpdate: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loading: false } });
  }
}
export function* TARIF_HISTORI_GUDANG(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingTarifHistori: true, dataTarifHistori: null }
  });
  try {
    const { data } = yield call(tarifHistoriGudang, action.data);
    yield put({ type: SET_STATE, payload: { dataTarifHistori: data } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingTarifHistori: false } });
  }
}

//warehouse category
export function* GET_WAREHOUSE_CATEGORY(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingCategory: true, dataCategory: null }
  });
  try {
    const { data } = yield call(getWarehouseCategory, action.param);
    yield put({ type: SET_STATE, payload: { dataCategory: data } });
  } catch ({ message: error }) {
    yield put({ type: SET_STATE, payload: { error } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingCategory: false } });
  }
}

// Warehouse Location

export function* GET_WAREHOUSE_LOCATION_BY_WH_ID(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingLocation: true, dataLocations: null, dataLocation: null }
  });
  try {
    const { data } = yield call(
      getWarehouseLocationByWarehouseId,
      action.param
    );
    yield put({ type: SET_STATE, payload: { dataLocations: data } });
  } catch ({ message: errorLocation }) {
    yield put({ type: SET_STATE, payload: { errorLocation } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingLocation: false } });
  }
}

export function* GET_WAREHOUSE_LOCATION_BY_ID(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingLocation: true, dataLocation: null }
  });
  try {
    const { data } = yield call(getWarehouseLocationById, action.id);
    yield put({ type: SET_STATE, payload: { dataLocation: data } });
  } catch ({ message: errorLocation }) {
    yield put({ type: SET_STATE, payload: { errorLocation } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingLocation: false } });
  }
}

export function* ADD_WAREHOUSE_LOCATION(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingLocation: true, isAddLocation: false }
  });
  try {
    yield call(addWarehouseLocation, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Warehouse', 'Create Warehouse Location');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isAddLocation: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingLocation: false } });
  }
}

export function* UPDATE_WAREHOUSE_LOCATION(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingLocation: true, isUpdateLocation: false }
  });
  try {
    yield call(updateWarehouseLocationById, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Warehouse', 'Edit Warehouse Location');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isUpdateLocation: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingLocation: false } });
  }
}

export function* DELETE_WAREHOUSE_LOCATION_BY_ID(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingLocation: true, isDeleteLocation: false }
  });
  try {
    yield call(deleteWarehouseLocationById, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Warehouse', 'Delete Warehouse Location');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isDeleteLocation: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingLocation: false } });
  }
}
// Storage Location

export function* GET_STORAGE_LOCATION_BY_LOCATION_ID(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingStorage: true, dataStorages: null, dataStorage: null }
  });
  try {
    const { data } = yield call(getStorageLocationByLocationId, action.param);
    yield put({ type: SET_STATE, payload: { dataStorages: data } });
  } catch ({ message: errorStorage }) {
    yield put({ type: SET_STATE, payload: { errorStorage } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingStorage: false } });
  }
}

export function* GET_STORAGE_LOCATION_BY_ID(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingStorage: true, dataStorage: null }
  });
  try {
    const { data } = yield call(getStorageLocationById, action.id);
    yield put({ type: SET_STATE, payload: { dataStorage: data } });
  } catch ({ message: errorStorage }) {
    yield put({ type: SET_STATE, payload: { errorStorage } });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingStorage: false } });
  }
}

export function* ADD_STORAGE_LOCATION(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingStorage: true, isAddStorage: false }
  });
  try {
    yield call(addStorageLocation, action.data);
    yield setIpUser();
    const body = bodyLog('create', 'Warehouse', 'Create Storage Location');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isAddStorage: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingStorage: false } });
  }
}

export function* UPDATE_STORAGE_LOCATION(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingStorage: true, isUpdateStorage: false }
  });
  try {
    yield call(updateStorageLocationById, action.data, action.id);
    yield setIpUser();
    const body = bodyLog('edit', 'Warehouse', 'Edit Storage Location');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isUpdateStorage: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingStorage: false } });
  }
}

export function* DELETE_STORAGE_LOCATION_BY_ID(action) {
  yield put({
    type: SET_STATE,
    payload: { loadingStorage: true, isDeleteStorage: false }
  });
  try {
    yield call(deleteStorageLocationById, action.id);
    yield setIpUser();
    const body = bodyLog('delete', 'Warehouse', 'Delete Storage Location');
    yield call(createLog, body);

    yield put({ type: SET_STATE, payload: { isDeleteStorage: true } });
  } catch ({ response }) {
    yield put({ type: SET_ERROR_FORM, error: response?.data });
  } finally {
    yield put({ type: SET_STATE, payload: { loadingStorage: false } });
  }
}

export default all([
  takeLatest(GET_DATA_GUDANG, GET_GUDANG),
  takeLatest(GET_DATA_GUDANG_2, GET_GUDANG_2),
  takeLatest(GET_DATA_GUDANGS, GET_GUDANGS),
  takeLatest(GET_DATA_GUDANG_BY_ID, GET_GUDANG_BY_ID),
  takeLatest(GET_DATA_GUDANG_NEXT_OR_PREV, GET_GUDANG_NEXT_OR_PREV),
  takeLatest(ADD_DATA_GUDANG, ADD_GUDANG),
  takeLatest(UPDATE_DATA_GUDANG, UPDATE_GUDANG),
  takeLatest(DELETE_DATA_GUDANG, DELETE_GUDANG),
  takeLatest(DOWNLOAD_TEMPLATE_GUDANG, DOWNLOAD_TEMPLATE_GUDANG_F),
  takeLatest(DOWNLOAD_DATA_GUDANG, DOWNLOAD_GUDANG),
  takeLatest(UPLOAD_DATA_GUDANG, UPLOAD_GUDANG),
  takeLatest(TARIF_HISTORI_DATA_GUDANG, TARIF_HISTORI_GUDANG),
  takeLatest(ACTIVATION_DATA_GUDANG, ACTIVATION_GUDANG),
  takeLatest(GET_DATA_WAREHOUSE_CATEGORY, GET_WAREHOUSE_CATEGORY),
  takeLatest(
    GET_DATA_WAREHOUSE_LOCATION_BY_WH_ID,
    GET_WAREHOUSE_LOCATION_BY_WH_ID
  ),
  takeLatest(GET_DATA_WAREHOUSE_LOCATION_BY_ID, GET_WAREHOUSE_LOCATION_BY_ID),
  takeLatest(ADD_DATA_WAREHOUSE_LOCATION, ADD_WAREHOUSE_LOCATION),
  takeLatest(UPDATE_DATA_WAREHOUSE_LOCATION, UPDATE_WAREHOUSE_LOCATION),
  takeLatest(
    DELETE_DATA_WAREHOUSE_LOCATION_BY_ID,
    DELETE_WAREHOUSE_LOCATION_BY_ID
  ),
  takeLatest(
    GET_DATA_STORAGE_LOCATION_BY_LOCATION_ID,
    GET_STORAGE_LOCATION_BY_LOCATION_ID
  ),
  takeLatest(GET_DATA_STORAGE_LOCATION_BY_ID, GET_STORAGE_LOCATION_BY_ID),
  takeLatest(ADD_DATA_STORAGE_LOCATION, ADD_STORAGE_LOCATION),
  takeLatest(UPDATE_DATA_STORAGE_LOCATION, UPDATE_STORAGE_LOCATION),
  takeLatest(DELETE_DATA_STORAGE_LOCATION_BY_ID, DELETE_STORAGE_LOCATION_BY_ID)
]);
