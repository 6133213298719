import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeSalesOrder = [
  {
    path: '/sales-order',
    isParent: true,
    action: 'view',
    parent: '/sales-order',
    component: lazy(() =>
      retry(() =>
        import(/*·webpackChunkName:·"SalesOrder"·*/ '../Pages/SalesOrder/')
      )
    )
  },
  {
    path: '/sales-order/:id',
    action: 'view',
    parent: '/sales-order',
    component: lazy(() =>
      retry(() =>
        import(/*·webpackChunkName:·"SalesOrder"·*/ '../Pages/SalesOrder/')
      )
    )
  },
  {
    path: '/sales-order/add',
    parent: '/sales-order',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "SalesOrderAdd" */ '../Pages/SalesOrder/salesOrderAdd'
        )
      )
    )
  }
];
