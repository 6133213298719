import { lazy } from 'react';
import { retry } from '../utils/index';

export const routeFishfinv2 = [
  {
    path: '/fishfin-changes',
    parent: '/fishfin-changes',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinChanges" */ '../Pages/FishFinV2/FishFinChanges'
        )
      )
    )
  },
  {
    parent: '/fishfin-changes',
    path: '/fishfin-changes/:id',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinChangesView" */ '../Pages/FishFinV2/FishFinChanges/detail'
        )
      )
    )
  },
  {
    parent: '/fishfin-changes',
    path: '/fishfin-changes/draft/:id',
    action: 'update',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinChangesUpdate" */ '../Pages/FishFinV2/FishFinChanges/edit'
        )
      )
    )
  },
  {
    path: '/fishfin-stock',
    parent: '/fishfin-stock',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinInventory" */ '../Pages/FishFinV2/FishFinInventory'
        )
      )
    )
  },
  {
    parent: '/fishfin-stock',
    path: '/fishfin-stock/:id',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinInventoryView" */ '../Pages/FishFinV2/FishFinInventory/detail'
        )
      )
    )
  },
  {
    parent: '/fishfin-stock',
    path: '/fishfin-stock/:id/detail',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinInventoryViewHistory" */ '../Pages/FishFinV2/FishFinInventory/HistoryDetail'
        )
      )
    )
  },
  {
    parent: '/fishfin-return',
    path: '/fishfin-return',
    action: 'view',
    isParent: true,
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinReturn" */ '../Pages/FishFinV2/FishFinReturn'
        )
      )
    )
  },
  {
    parent: '/fishfin-return',
    path: '/fishfin-return/add/:idAdd',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinReturnAdd" */ '../Pages/FishFinV2/FishFinReturn/add'
        )
      )
    )
  },
  {
    parent: '/fishfin-return',
    path: '/fishfin-return/draft/:idDraft',
    action: 'create',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinReturnDraft" */ '../Pages/FishFinV2/FishFinReturn/add'
        )
      )
    )
  },
  {
    parent: '/fishfin-return',
    path: '/fishfin-return/:id',
    action: 'view',
    component: lazy(() =>
      retry(() =>
        import(
          /* webpackChunkName: "FishfinReturnView" */ '../Pages/FishFinV2/FishFinReturn/detail'
        )
      )
    )
  }
];
