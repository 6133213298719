import { defaultActionCreator } from '../../actionCreator';

export const SET_STATE = 'fishfinChangesReturn/SET_STATE';
export const setState = defaultActionCreator(SET_STATE, 'payload');

export const SET_ERROR_FORM = 'fishfinChangesReturn/SET_ERROR_FORM';
export const setStateErrorForm = defaultActionCreator(SET_ERROR_FORM, 'error');

export const GET_DATA_FISHFIN_CHANGES_RETURN =
  'GET_DATA_FISHFIN_CHANGES_RETURN';
export const getDataFishfinChangesReturn = defaultActionCreator(
  GET_DATA_FISHFIN_CHANGES_RETURN,
  'param'
);

export const GET_DATA_FISHFIN_CHANGES_RETURN_BY_ID =
  'GET_DATA_FISHFIN_CHANGES_RETURN_BY_ID';
export const getDataFishfinChangesReturnById = defaultActionCreator(
  GET_DATA_FISHFIN_CHANGES_RETURN_BY_ID,
  'id'
);

export const GET_DATA_FISHFIN_CHANGES_RETURN_LIST_PRODUCT =
  'GET_DATA_FISHFIN_CHANGES_RETURN_LIST_PRODUCT';
export const getDataFishfinChangesReturnListProduct = defaultActionCreator(
  GET_DATA_FISHFIN_CHANGES_RETURN_LIST_PRODUCT,
  'id'
);

export const CREATE_DATA_FISHFIN_CHANGES_RETURN =
  'CREATE_DATA_FISHFIN_CHANGES_RETURN';
export const createDataFishfinChangesReturn = defaultActionCreator(
  CREATE_DATA_FISHFIN_CHANGES_RETURN,
  'data',
  'log'
);

export const DELETE_DATA_FISHFIN_CHANGES_RETURN =
  'DELETE_DATA_FISHFIN_CHANGES_RETURN';
export const deleteDataFishfinChangesReturn = defaultActionCreator(
  DELETE_DATA_FISHFIN_CHANGES_RETURN,
  'id'
);

export const DOWNLOAD_DATA_FISHFIN_CHANGES_RETURN =
  'DOWNLOAD_DATA_FISHFIN_CHANGES_RETURN';
export const downloadDataFishfinChangesReturn = defaultActionCreator(
  DOWNLOAD_DATA_FISHFIN_CHANGES_RETURN,
  'param'
);
