import service from './service';
import endpoint from './constantUrl';

//fishfin v2
//fishfin changes
export const createFishfinChanges = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin/`,
    data
  );
};

export const getFishfinChanges = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-list/`,
    param
  );
};

export const getFishfinChangesDetail = (id62) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin/`
  );
};

export const deleteFishfinChanges = (id62) => {
  return service.delete(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-delete/`
  );
};

export const downloadExcelFishfinChanges = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-excel/`,
    data
  );
};

//fishfin return
export const createFishfinReturn = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return/`,
    data
  );
};

export const getFishfinReturn = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return-list/`,
    param
  );
};

export const getFishfinReturnDetail = (id62) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-return/`
  );
};

export const deleteFishfinReturn = (id62) => {
  return service.delete(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-return-delete/`
  );
};

export const downloadExcelFishfinReturn = (data) => {
  return service.postArrayBuffer(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return-excel/`,
    data
  );
};

// fishfin v3
export const getFishfinChangesV3 = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin/filter/v3/`,
    param
  );
};

export const createFishfinChangesV3 = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin/v3/`,
    data
  );
};

export const getFishfinChangesDetailV3 = (id62) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin/v3/`
  );
};

export const getFishfinChangesListProductV3 = (id62, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin/list-product/v3/`,
    param
  );
};

export const getStockStorageLocation = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-storage-location/all/`,
    param
  );
};

// fishfin stock
export const getFishfinStock = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/fishfin-stock/filter/`,
    param
  );
};

export const getFishfinStockBatches = (id62, param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/fishfin-stock/${id62}/batches/`,
    param
  );
};

export const getFishfinStockDetailBatch = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/fishfin-stock/detail-batch/`,
    param
  );
};

// fishfin return v3
export const createFishfinReturnV3 = (data) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return/v3/`,
    data
  );
};

export const getFishfinReturnV3 = (param) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/change-fishfin-return/filter/v3/`,
    param
  );
};

export const getFishfinReturnDetailV3 = (id62) => {
  return service.get(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-return/v3/`
  );
};

export const getFishfinReturnDetailBatchV3 = (id62) => {
  return service.post(
    `${endpoint.POS_URL}/inventory/stock-inventory/${id62}/change-fishfin-return/list-product/v3/`
  );
};
